// 引入 axios
import axios from 'axios'
import { Message, MessageBox } from 'element-ui'
import { baseUrl } from '@/config'
import router from '@/router/index'
import { getToken, removeToken } from '@/util/auth'
import { isNotNull } from '@/util/index'
import NProgress from 'nprogress' // progress bar
import 'nprogress/nprogress.css' // progress bar style

NProgress.configure({
  showSpinner: false
})

axios.defaults.baseURL = baseUrl

// 超时时间
axios.defaults.timeout = 10000

let messageBoxShowing = false

// 请求时的拦截
axios.interceptors.request.use(conf => {
  NProgress.start() // start progress bar
  conf.headers.common.token = getToken()

  // get参数编码
  let url = conf.url
  if (conf.method === 'get' && conf.params) {
    url += '?'
    const keys = Object.keys(conf.params)
    for (const key of keys) {
      if (isNotNull(conf.params[key])) {
        url += `${key}=${encodeURIComponent(conf.params[key])}&`
      }
    }
    url = url.substring(0, url.length - 1)
    conf.params = {}
  }
  conf.url = url

  return conf
}, function (error) {
  return Promise.reject(error)
})

// 响应拦截
axios.interceptors.response.use(response => {
  NProgress.done()
  if (response.status === 200) {
    return Promise.resolve(response)
  } else {
    return Promise.reject(response)
  }
},
// 服务器状态码不是200的情况
error => {
  NProgress.done()
  if (!error.response) {
    // todo 请求异常
  }
  if (error.response.status) {
    switch (error.response.status) {
      case 401:
        if (messageBoxShowing === true) {
          return false
        }
        messageBoxShowing = true
        MessageBox.alert('登录信息已经过期，请重新登录', '提示', {
          confirmButtonText: '确定',
          callback: () => {
            removeToken()
            router.push({ path: '/login' })
            messageBoxShowing = false
          }
        })
        break
      case 404:
        Message.error('网络请求不存在')
        break
      default:
        Message.error(error.response.data.msg)
    }
  }
  return Promise.reject(error.response)
}
)

export function get (url, params) {
  return new Promise((resolve, reject) => {
    axios.get(url, {
      params: params
    }).then(res => {
      resolve(res.data)
    }).catch(err => {
      reject(err.data)
    })
  })
}

export function post (url, params) {
  return new Promise((resolve, reject) => {
    axios.post(url, params).then(res => {
      resolve(res.data)
    }).catch(err => {
      reject(err.data)
    })
  })
}

export function request (config) {
  return new Promise((resolve, reject) => {
    axios.request(config).then(res => {
      resolve(res.data)
    }).catch(err => {
      reject(err.data)
    })
  })
}

export default axios
