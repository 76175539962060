import Vue from 'vue'
import Vuex from 'vuex'
import user from './module/user'
import tags from './module/tags'
import common from './module/common'
import getters from './getters'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    user,
    tags,
    common
  },
  getters
})
