/**
 * 全局配置文件
 */
export default {
  // 配置主键,目前用于存储
  key: 'good',
  fistPage: {
    label: '首页',
    value: '/index',
    params: {},
    query: {},
    meta: {
      i18n: 'dashboard'
    },
    group: [],
    close: false
  }, // 同时展示两级菜单
  useSecMenu: false, // 配置首页不可关闭
  isFirstPage: false, // 配置菜单的属性
  // 配置菜单的属性
  menuDefault: {
    iconDefault: 'icon-caidan',
    label: 'name',
    path: 'path',
    icon: 'icon',
    children: 'children',
    query: 'query',
    href: 'href',
    meta: 'meta'
  },
  sessionStorageKey: ['permission', 'menu', 'tag']
}
