import { get, post } from '@/util/http'

export const loginByUsername = (dto) =>
  post('/anon/login', dto)

export const getUserInfo = () => get('/sys/user/info')

export const refreshToken = () => post('/user/refresh')

export const getTopMenu = () => get('/user/getTopMenu')

export const sendLogs = (list) => post('/user/logout', list)

