<template>
  <el-autocomplete
      class="top-search"
      popper-class="my-autocomplete"
      v-model="value"
      :fetch-suggestions="querySearch"
      placeholder="请输入搜索内容"
      @select="handleSelect">
    <template slot-scope="{ item }">
      <i :class="[item[iconKey],'icon']"></i>
      <div class="name">{{ item[labelKey] }}</div>
      <div class="addr">{{ item[pathKey] }}</div>
    </template>
  </el-autocomplete>
</template>

<script>
import { mapGetters } from 'vuex'
import { validateNull } from '@/util/validate'

export default {
  data () {
    return {
      value: '',
      menuList: []
    }
  },
  created () {
    this.getMenuList()
  },

  watch: {
    menu () {
      this.getMenuList()
    }
  },
  computed: {
    labelKey () {
      return this.website.menuDefault.label
    },
    pathKey () {
      return this.website.menuDefault.path
    },
    iconKey () {
      return this.website.menuDefault.icon
    },
    childrenKey () {
      return this.website.menuDefault.children
    },
    ...mapGetters(['menu'])
  },
  methods: {
    getMenuList () {
      const findMenu = list => {
        for (let i = 0; i < list.length; i++) {
          const ele = Object.assign({}, list[i])
          if (validateNull(ele[this.childrenKey])) {
            this.menuList.push(ele)
          } else {
            findMenu(ele[this.childrenKey])
          }
        }
      }
      this.menuList = []
      findMenu(this.menu)
    },
    querySearch (queryString, cb) {
      const restaurants = this.menuList
      const results = queryString
        ? restaurants.filter(this.createFilter(queryString))
        : restaurants
      // 调用 callback 返回建议列表的数据
      cb(results)
    },
    createFilter (queryString) {
      return restaurant => {
        return (
          restaurant.name.toLowerCase().indexOf((queryString += '').toLowerCase()) ===
            0
        )
      }
    },
    handleSelect (item) {
      this.value = ''
      this.$router.push({
        path: item[this.pathKey],
        query: item.query
      })
    }
  }
}
</script>

<style lang="scss">
.my-autocomplete {
  li {
    line-height: normal;
    padding: 7px;

    .icon {
      margin-right: 5px;
      display: inline-block;
      vertical-align: middle;
    }

    .name {
      display: inline-block;
      text-overflow: ellipsis;
      overflow: hidden;
      vertical-align: middle;
    }

    .addr {
      padding-top: 5px;
      width: 100%;
      font-size: 12px;
      color: #b4b4b4;
    }

    .highlighted .addr {
      color: #ddd;
    }
  }
}
</style>
