<template>
  <div class="vue-content" :class="{'vue--collapse':isCollapse}">
    <div class="vue-header">
      <!-- 顶部导航栏 -->
      <top/>
    </div>
    <div class="vue-layout">
      <div>
        <!-- 左侧导航栏 -->
        <sec-bar v-if="useSecMenu"/>
        <sidebar v-else/>
      </div>
      <div class="vue-main">
        <tags />
        <div style="display:flex;height: 100%;">
          <!-- 主体视图层 -->
          <el-scrollbar style="height:100%;flex:1">
            <keep-alive>
              <router-view class="vue-view" v-if="$route.meta.keepAlive && isRefresh"/>
            </keep-alive>
            <router-view class="vue-view" v-if="!$route.meta.keepAlive && isRefresh"/>
          </el-scrollbar>
        </div>
      </div>
    </div>
    <div class="vue-shade"></div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import tags from './tags'
import top from './top/'
import sidebar from './sidebar/'
import secBar from './secbar/'
import website from '@/config/website'
import admin from '@/util/admin'
export default {
  components: {
    top,
    sidebar,
    tags,
    secBar
  },
  name: 'Index',
  data () {
    return {
      useSecMenu: website.useSecMenu
    }
  },
  created () {
    // 实时检测刷新token
  },
  mounted () {
    this.init()
  },
  computed: mapGetters(['isCollapse', 'isRefresh']),
  props: [],
  methods: {
    // 屏幕检测
    init () {
      this.$store.commit('SET_SCREEN', admin.getScreen())
      window.onresize = () => {
        setTimeout(() => {
          this.$store.commit('SET_SCREEN', admin.getScreen())
        }, 0)
      }
    }
  }
}
</script>
