const getters = {
  // user 相关信息
  userInfo: state => state.user.userInfo,
  permission: state => state.user.permission,
  menu: state => state.user.menu,
  token: state => state.user.token,
  isRefresh: state => state.common.isRefresh,
  menuDefault: state => state.common.menuDefault,
  website: state => state.common.website,
  screen: state => state.common.screen,
  tagList: state => state.tags.tagList,
  tagWel: state => state.tags.tagWel,
  tag: state => state.tags.tag,
  themeName: state => state.common.themeName,
  isCollapse: state => state.common.isCollapse
}
export default getters
