import { setStore, getStore, removeStore } from '@/util/storage'

const TokenKey = 'token'

export function getToken () {
  return getStore({ name: TokenKey, type: '' })
}

export function setToken (token) {
  return setStore({
    name: TokenKey, content: token, type: ''
  })
}

export function removeToken () {
  return removeStore({
    name: TokenKey, type: ''
  })
}
