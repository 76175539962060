<template>
  <div class="vue-tags">
    <div
        class="vue-tags__box"
        :class="{'vue-tags__box--close':!website.isFirstPage}">
      <el-tabs
          v-model="active"
          type="card"
          @contextmenu.native="handleContextMenu"
          :closable="tagLen!==1"
          @tab-click="openTag"
          @edit="menuTag">
        <el-tab-pane
            v-for="item in tagList"
            :key="item.value"
            :label=item.label
            :name="item.value">
          <span slot="label">
            {{ item.label }}
            <i
                class="el-icon-refresh"
                :class="{'turn':refresh}"
                @click="handleRefresh"
                v-if="active === item.value"></i>
          </span>
        </el-tab-pane>
      </el-tabs>
      <el-dropdown class="vue-tags__menu">
        <el-button
            type="primary"
            size="mini">
          更多
          <i class="el-icon-arrow-down el-icon--right"></i>
        </el-button>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item @click.native="closeOthersTags">关闭其它</el-dropdown-item>
          <el-dropdown-item @click.native="closeAllTags">关闭所有</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>

  </div>
</template>
<script>
import { mapGetters, mapState } from 'vuex'

export default {
  name: 'Tags',
  data () {
    return {
      refresh: false,
      active: ''
    }
  },
  created () {},
  mounted () {
    this.setActive()
  },
  watch: {
    tag () {
      this.setActive()
    }
  },
  computed: {
    ...mapGetters(['tagWel', 'tagList', 'tag', 'website']),
    ...mapState({}),
    tagLen () {
      return this.tagList.length || 0
    }
  },
  methods: {
    handleRefresh () {
      this.refresh = true
      this.$store.commit('SET_IS_REFRESH', false)
      setTimeout(() => {
        this.$store.commit('SET_IS_REFRESH', true)
      }, 100)
      setTimeout(() => {
        this.refresh = false
      }, 500)
    },
    handleContextMenu (event) {
      let target = event.target
      // 解决 https://github.com/d2-projects/d2-admin/issues/54
      let flag = false
      if (target.className.indexOf('el-tabs__item') > -1) flag = true
      else if (target.parentNode.className.indexOf('el-tabs__item') > -1) {
        target = target.parentNode
        flag = true
      }
      if (flag) {
        event.preventDefault()
        event.stopPropagation()
        this.tagName = target.getAttribute('aria-controls').slice(5)
      }
    },
    // 激活当前选项
    setActive () {
      this.active = this.tag.value
    },
    menuTag (value, action) {
      if (action === 'remove') {
        let { tag, key } = this.findTag(value)
        this.$store.commit('DEL_TAG', tag)
        if (tag.value === this.tag.value) {
          tag = this.tagList[key === 0 ? key : key - 1] // 如果关闭本标签让前推一个
          this.openTag(tag)
        }
      }
    },
    openTag (item) {
      let tag
      if (item.name) {
        tag = this.findTag(item.name).tag
      } else {
        tag = item
      }
      this.$router.push({
        path: this.$router.$vueRouter.getPath({
          name: tag.label,
          src: tag.value
        }),
        query: tag.query
      })
    },
    closeOthersTags () {
      this.$store.commit('DEL_TAG_OTHER')
    },
    findTag (value) {
      let tag, key
      this.tagList.forEach((item, index) => {
        if (item.value === value) {
          tag = item
          key = index
        }
      })
      return { tag: tag, key: key }
    },
    closeAllTags () {
      this.$store.commit('DEL_ALL_TAG')
      this.$router.push({
        path: this.$router.$vueRouter.getPath({
          src: this.tagWel.value
        }),
        query: this.tagWel.query
      })
    }
  }
}
</script>

