const title = 'HERMANNBACH'

const flowUIUrl = 'http://localhost:7081'
// baseUrl: 'http://localhost:6626/admin'
const config = {
  development: {
    baseUrl: 'http://localhost:6626/admin'
  },
  production: {
    baseUrl: 'https://www.hermannbach.cn/admin'
  },
  s: {
    baseUrl: 'https://www.hermannbach.cn/admin'
  }
}
const env = process.env

const baseUrl = config[env.NODE_ENV].baseUrl
const flowUrl = baseUrl + '/flow/process/diagram-view?processInstanceId='

const aliOss = {
  region: 'oss-cn-shanghai',
  endpoint: 'oss-cn-shanghai.aliyuncs.com',
  accessKeyId: 'LTAIFkVdXgr3ryiG',
  accessKeySecret: 'jBTEHwGvwdDmHxjbKcSWHSkpxp82Th',
  bucket: 'good-flow'
}

export {
  aliOss,
  flowUIUrl,
  flowUrl,
  title,
  baseUrl
}
