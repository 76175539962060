import website from '@/config/website'

import {
  setStore, getStore
} from '@/util/storage'
import { isNotNull } from '../../util'

const common = {
  state: {
    menuDefault: website.menuDefault,
    isCollapse: false,
    screen: -1,
    isRefresh: true,
    themeName: getStore({ name: 'themeName' }) || 'theme-default',
    website: website
  },
  mutations: {
    SET_IS_REFRESH: (state, refresh) => {
      state.isRefresh = refresh
    },
    SET_SCREEN: (state, screen) => {
      state.screen = screen
    },
    SET_COLLAPSE: (state, isCollapse) => {
      if (isNotNull(isCollapse)) {
        state.isCollapse = isCollapse
      } else {
        state.isCollapse = !state.isCollapse
      }
    },
    SET_THEME_NAME: (state, themeName) => {
      state.themeName = themeName
      setStore({ name: 'themeName', content: state.themeName })
    }
  }
}
export default common
